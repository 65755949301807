import { THEME_ENUM } from "constants/theme.constant";

export const themeConfig = {
  themeColor: "orange",
  direction: "ltr",
  mode: "dark",
  locale: "en",
  primaryColorLevel: 400,
  cardBordered: false,
  panelExpand: false,
  controlSize: "md",
  navMode: "dark",
  layout: {
    type: "classic",
    sideNavCollapse: false,
  },
};
