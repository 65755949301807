import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
manualKYCList: [],
};

export const manualKYCSlice = createSlice({
  name: "manualKYC",
  initialState,
  reducers: {
    setManualKYCList: (state, action) => {
      state.manualKYCList = action.payload;
    },
  },
});

export const { setManualKYCList } = manualKYCSlice.actions;

export default manualKYCSlice.reducer;