import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  bookingInfo: [],
};

export const bookingInfoSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setBookingInfo: (state, action) => {
      state.bookingInfo = action.payload;
    },
  },
});

export const { setBookingInfo } = bookingInfoSlice.actions;

export default bookingInfoSlice.reducer;
