import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  usersInfo: [],
};

export const usersInfoSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersInfo: (state, action) => {
      state.usersInfo = action.payload;
    },
  },
});

export const { setUsersInfo } = usersInfoSlice.actions;

export default usersInfoSlice.reducer;